import { render, staticRenderFns } from "./updatetype.vue?vue&type=template&id=3c4815e6&scoped=true&"
import script from "./updatetype.vue?vue&type=script&lang=js&"
export * from "./updatetype.vue?vue&type=script&lang=js&"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./updatetype.vue?vue&type=style&index=1&id=3c4815e6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c4815e6",
  null
  
)

export default component.exports